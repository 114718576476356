<script>
import Navbar2 from "@/components/navbar2";
import Terms from "@/components/terminos";
import Footer from "@/components/footer";

/**
 * Publicaciones...
 */
export default {
  components: { Navbar2, Terms, Footer }
};
</script>

<template>
  <div>
    <Navbar2 />
    <div v-scroll-spy>
      <Terms />
      <Footer />
    </div>
  </div>
</template>